<template>
  <div class="animated fadeIn">
    <CardTable
      :title="title"
      :columns="table.columns"
      :rows="table.rows"
      :isLoading="table.isLoading"
      :pageSize="serverParams.pageSize"
      :pageNumber="serverParams.pageNumber"
      :totalPage="table.totalPage"
      :totalRecords="table.totalRecords"
      @onRowSelected="onRowSelected"
      @onPageChange="onPageChange"
    >
      <template #filter>
        <SectionFilter :serverParams="serverParams" @onChange="getAll" />
      </template>
      <template #cell(value)="data">
        <template v-if="data.item.type.id == 5">
          <b-badge v-if="data.item.value == 1" variant="success">TRUE</b-badge>
          <b-badge v-else-if="data.item.value == 0" variant="danger">FALSE</b-badge>
        </template>
        <template v-else>
          {{ data.item.value }}
        </template>
      </template>
    </CardTable>
  </div>
</template>

<script>
import { configuration as columns } from "@/shared/columns";
import SectionFilter from "./SectionFilter.vue";

export default {
  components: {
    SectionFilter,
  },
  data: () => ({
    title: "Configuration",
    serverParams: {
      pageNumber: 1,
      pageSize: 100,
      keyword: null,
    },
    table: {
      isLoading: true,
      columns,
      rows: [],
      totalPage: 0,
      totalRecords: 0,
    },
  }),
  created() {
    const self = this;
    self.getAll();
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onRowSelected(items) {
      const self = this;
      self.$router.push({
        name: "ConfigurationUpdate",
        params: {
          id: items[0].key,
          buildingId: self.serverParams.buildingId,
        },
      });
    },
    onPageChange(params) {
      this.updateParams({ pageNumber: params });
      this.getAll();
    },
    getAll() {
      const self = this;

      self.table.isLoading = true;
      self.$store
        .dispatch("apis/get", {
          url: "/common/configuration",
          params: self.serverParams,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            self.table.rows = response.data.data;
            self.table.totalPage = response.data.totalPage;
            self.table.totalRecords = response.data.totalData;
          }
          self.table.isLoading = false;
        });
    },
  },
};
</script>
