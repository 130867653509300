<template>
  <div>
    <div class="px-3 py-2">
      <b-row class="align-items-center">
        <b-col sm="4" offset-sm="8">
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text>
                <i class="icon-magnifier"></i>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input type="text"
                          class="form-control"
                          placeholder="Search by key"
                          autocomplete="off"
                          v-model="serverParams.keyword"
                          @input="onChange" />
          </b-input-group>
        </b-col>
        <b-col sm="4">
          <BuildingDropdown :server-params="serverParams" :on-select="onChange" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import BuildingDropdown from "@/components/common/BuildingDropdown";
  export default {
    props: {
      serverParams: Object,
    },
    components: {
      BuildingDropdown,
    },
    methods: {
      onChange() {
        this.$emit("onChange", this.serverParams);
      },
    },
  };
</script>
